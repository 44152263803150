import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "../api/api";
import { components } from "@/types/api";
import { deleteUser } from "@/api/users";
import usersQueries from "@/queries/users";
import { UserPreferences, userPreferencesSchema } from "@/schemas";
import { merge } from "ts-deepmerge";
import { DeepPartial } from "@/utils";
async function login({
  email,
  password,
}: components["schemas"]["AuthRequestDTO"]) {
  const { data } = await api.POST("/api/login", {
    body: {
      email,
      password,
    },
  });

  return data;
}

export const useLogin = () => {
  return useMutation({
    mutationFn: login,
  });
};

async function logout() {
  const { data } = await api.POST("/api/logout");

  return data;
}

export const useLogout = () => {
  return useMutation({
    mutationFn: logout,
  });
};

async function addUser({
  username,
  fullName,
  email,
  role,
  state,
  visibility,
  customerId,
  jobs,
}: components["schemas"]["BasicUserDTO"]) {
  const { data } = await api.POST("/api/User", {
    body: {
      username,
      fullName,
      email,
      role,
      state,
      visibility,
      customerId,
      jobs,
    },
  });

  return data;
}

export const useAddUser = () => {
  return useMutation({
    mutationFn: addUser,
  });
};

async function updateUser({
  id,
  username,
  fullName,
  email,
  role,
  state,
  visibility,
  customerId,
  jobs,
}: components["schemas"]["BasicUserDTO"]) {
  const { data } = await api.PUT("/api/User", {
    body: {
      id,
      fullName,
      username,
      email,
      role,
      state,
      visibility,
      customerId,
      jobs,
    },
  });

  return data;
}

export const useUpdateUser = () => {
  return useMutation({
    mutationFn: updateUser,
  });
};

async function setNote({
  dbid,
  comment,
}: components["schemas"]["TicketCommentRequestDTO"]) {
  const { data } = await api.PUT("/api/Ticket/SetTicketComment", {
    body: {
      dbid,
      comment,
    },
  });

  return data;
}

export const useSetNote = () => {
  return useMutation({
    mutationFn: setNote,
  });
};

async function sendUserInvite({
  email,
  inviteType = 0,
}: components["schemas"]["UserInviteDTO"]) {
  const { data } = await api.PUT("/api/User/SendUserInvite", {
    body: {
      email,
      inviteType,
    },
  });

  return data;
}

export const useSendUserInvite = () => {
  return useMutation({
    mutationFn: sendUserInvite,
  });
};

async function changeUserState({
  email,
  newState,
}: components["schemas"]["ChangeUserStateDTO"]) {
  const { data } = await api.PUT("/api/User/ChangeUserState", {
    body: {
      email,
      newState,
    },
  });

  return data;
}

export const useChangeUserState = () => {
  return useMutation({
    mutationFn: changeUserState,
  });
};

async function changeUserPassword({
  token,
  password,
}: components["schemas"]["ChangeUserPasswordDTO"]) {
  const { data } = await api.PUT("/api/User/ChangeUserPassword", {
    body: {
      token,
      password,
    },
  });

  return data;
}

export const useChangeUserPassword = () => {
  return useMutation({
    mutationFn: changeUserPassword,
  });
};

async function changeUserPasswordWithOld({
  old_password,
  new_password,
}: components["schemas"]["ChangeUserPasswordWithOldDTO"]) {
  const { data } = await api.PUT("/api/User/ChangeUserPasswordWithOld", {
    body: {
      old_password,
      new_password,
    },
  });

  return data;
}

export const useChangeUserPasswordWithOld = () => {
  return useMutation({
    mutationFn: changeUserPasswordWithOld,
  });
};

export const changeUserPreferences = async ({
  jsonUserPreferences,
}: components["schemas"]["UserPreferencesRequestDTO"]) => {
  const { data } = await api.POST("/api/User/SetUserPreferences", {
    body: {
      jsonUserPreferences: JSON.stringify(jsonUserPreferences),
    },
  });

  return data;
};

async function createCustomer({
  customerName,
  customerId,
}: components["schemas"]["CustomerDTO"]) {
  const { data } = await api.POST("/api/Customer", {
    body: {
      customerName,
      customerId,
    },
  });

  return data;
}

export const useCreateCustomer = () => {
  return useMutation({
    mutationFn: createCustomer,
  });
};

async function createJob({
  customerId,
  jobName,
  jobId,
}: components["schemas"]["CustomerJobDTO"]) {
  const { data } = await api.POST("/api/Jobs", {
    body: {
      customerId,
      jobName,
      jobId,
    },
  });

  return data;
}

export const useCreateJob = () => {
  return useMutation({
    mutationFn: createJob,
  });
};

export const useDeleteUser = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteUser,
    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: usersQueries.all(),
      });
    },
  });
};

export const useUpdateUserPreferences = () => {
  const queryClient = useQueryClient();
  const oldUserPreferences = queryClient.getQueryData(
    usersQueries.preferences().queryKey
  )!;


  return useMutation({
    mutationFn: async (update: DeepPartial<UserPreferences>) => {
      const newUserPreferences = userPreferencesSchema.parse(
        merge(oldUserPreferences, update)
      );
      if (update.tableOrderedFields?.length) {
        newUserPreferences.tableOrderedFields = update.tableOrderedFields?.filter((field): field is string => field !== undefined);
      }
      if (update.tablePinnedColumns?.left?.length || update.tablePinnedColumns?.right?.length) {
        newUserPreferences.tablePinnedColumns = {
          left: update.tablePinnedColumns?.left?.filter((field): field is string => field !== undefined),
          right: update.tablePinnedColumns?.right?.filter((field): field is string => field !== undefined),
        };
      }
      if (update.defaultTableState) {
        newUserPreferences.defaultTableState = false;
        delete newUserPreferences.tableColumnWidths;
        delete newUserPreferences.tableHiddenColumns;
        newUserPreferences.tablePinnedColumns = {
          left: [],
          right: [],
        };
      }

      await changeUserPreferences({
        // @ts-expect-error - the JSON is stringified automatically
        jsonUserPreferences: newUserPreferences,
      });
    },
    onMutate: async (update) => {
      await queryClient.cancelQueries({
        queryKey: usersQueries.preferences().queryKey,
      });

      const newUserPreferences = userPreferencesSchema.parse(
        merge(oldUserPreferences, update)
      );

      queryClient.setQueryData(
        usersQueries.preferences().queryKey,
        newUserPreferences
      );

      return {
        oldUserPreferences,
      };
    },
    onSettled: () => queryClient.invalidateQueries(usersQueries.preferences()),
  });
};
